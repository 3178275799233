import React from "react";
import "./NotFound.css";

export default () => (
  <div className="NotFound">
    <h2
      onClick={() => {
        throw new Error("Sentry Frontend:  Error Testing!!");
      }}
    >
      Sorry, page not found!
    </h2>
    <a href="/" className="btn btn-primary mt-5">
      Back to home page
    </a>
  </div>
);
